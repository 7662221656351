import React, {useEffect} from "react";
import {Container} from "react-bootstrap";

export default function Error404() {

    return (
        <Container className={"container-small"}>
            <div className={"text-justify"}>

                <h1 className={"h1 pb-4"}>
                    well, it's not here
                </h1>

                <p>
                    The page you are looking for does not exist or has been moved. Please use the navigation above to find what you are looking for. If you think this is a mistake, please contact me by clicking <a href={"/contact"} className={"link"}>here</a>.
                </p>

                <img src={"assets/images/404.png"} className={"img-center"} alt={"error 404"}/>

            </div>
        </Container>
    );

}